import Vivus from "vivus";

// Include scss
import "../scss/_header.scss";

// Images
import "./images.js";

// Fonts preloadin

let loadAnimation = false;
let interval = false;
let onload = false;

function loader() {
    let loader = document.querySelector('#loader');

    if (loader) {
        loader.style.display = 'block';
    }

    loadAnimation = new Vivus('js-loader-animate-svg', {
        type: 'delayed',
        duration: 100,
        delay: 1,
        animTimingFunction: Vivus.EASE
    }, () => {});

    loadAnimation.stop();

    let iteration = 0;
    let last = 0;
    let i = 0;

    interval = setInterval(function() {
        window.requestAnimationFrame( function() {
            iteration += 0.002 - (iteration * 0.002) * 1.8;
            //console.log(iteration, (iteration - last), onload);
            last = iteration;

            if(iteration > 1 || onload) {
                //console.log("END", i);
                clearInterval(interval);
                return;
            }
            loadAnimation.setFrameProgress(iteration);
        })
    }, 10);
}

window.addEventListener('load', function () {
    // Ждём если либа отработала позже чем загрузилась страница пр прекрасном интернете(кеше)
    var afterAnimation = setInterval(()=>{
        if(loadAnimation) {
            onload = true;
            clearInterval(interval);
            loadAnimation.play();
            // console.log("LOAD");
            clearInterval(afterAnimation);
        }
    }, 100);
});

var loading = setInterval(()=>{
    if(document.querySelector('#loader')) {
        loader();
        clearInterval(loading);
    }
}, 100);
