require('../../src/img/logo.webp'); require('../../src/img/logox2.webp'); require('../../src/img/logox3.webp'); require('../../src/img/logo.png'); require('../../src/img/logox2.png'); require('../../src/img/logox3.png'); 
require('../../src/img/main_page/box.webp'); require('../../src/img/main_page/boxx2.webp'); require('../../src/img/main_page/boxx3.webp'); require('../../src/img/main_page/box.png'); require('../../src/img/main_page/boxx2.png'); require('../../src/img/main_page/boxx3.png'); 
require('../../src/img/main_page/case_1.webp'); require('../../src/img/main_page/case_1x2.webp'); require('../../src/img/main_page/case_1x3.webp'); require('../../src/img/main_page/case_1.png'); require('../../src/img/main_page/case_1x2.png'); require('../../src/img/main_page/case_1x3.png'); 
require('../../src/img/main_page/case_2.webp'); require('../../src/img/main_page/case_2x2.webp'); require('../../src/img/main_page/case_2x3.webp'); require('../../src/img/main_page/case_2.png'); require('../../src/img/main_page/case_2x2.png'); require('../../src/img/main_page/case_2x3.png'); 
require('../../src/img/main_page/case_3.webp'); require('../../src/img/main_page/case_3x2.webp'); require('../../src/img/main_page/case_3x3.webp'); require('../../src/img/main_page/case_3.png'); require('../../src/img/main_page/case_3x2.png'); require('../../src/img/main_page/case_3x3.png'); 
require('../../src/img/main_page/case_4.webp'); require('../../src/img/main_page/case_4x2.webp'); require('../../src/img/main_page/case_4x3.webp'); require('../../src/img/main_page/case_4.png'); require('../../src/img/main_page/case_4x2.png'); require('../../src/img/main_page/case_4x3.png'); 
require('../../src/img/main_page/client_1.webp'); require('../../src/img/main_page/client_1x2.webp'); require('../../src/img/main_page/client_1x3.webp'); require('../../src/img/main_page/client_1.png'); require('../../src/img/main_page/client_1x2.png'); require('../../src/img/main_page/client_1x3.png'); 
require('../../src/img/main_page/client_2.webp'); require('../../src/img/main_page/client_2x2.webp'); require('../../src/img/main_page/client_2x3.webp'); require('../../src/img/main_page/client_2.png'); require('../../src/img/main_page/client_2x2.png'); require('../../src/img/main_page/client_2x3.png'); 
require('../../src/img/main_page/client_3.webp'); require('../../src/img/main_page/client_3x2.webp'); require('../../src/img/main_page/client_3x3.webp'); require('../../src/img/main_page/client_3.png'); require('../../src/img/main_page/client_3x2.png'); require('../../src/img/main_page/client_3x3.png'); 
require('../../src/img/main_page/contact_box_mp.webp'); require('../../src/img/main_page/contact_box_mpx2.webp'); require('../../src/img/main_page/contact_box_mpx3.webp'); require('../../src/img/main_page/contact_box_mp.png'); require('../../src/img/main_page/contact_box_mpx2.png'); require('../../src/img/main_page/contact_box_mpx3.png'); 
require('../../src/img/main_page/frame-34.webp'); require('../../src/img/main_page/frame-34x2.webp'); require('../../src/img/main_page/frame-34x3.webp'); require('../../src/img/main_page/frame-34.png'); require('../../src/img/main_page/frame-34x2.png'); require('../../src/img/main_page/frame-34x3.png'); 
require('../../src/img/main_page/review_user.webp'); require('../../src/img/main_page/review_userx2.webp'); require('../../src/img/main_page/review_userx3.webp'); require('../../src/img/main_page/review_user.png'); require('../../src/img/main_page/review_userx2.png'); require('../../src/img/main_page/review_userx3.png'); 
require('../../src/img/svg/c_close.svg');require('../../src/img/svg/close.svg');require('../../src/img/svg/contact_border.svg');require('../../src/img/svg/contact_icon.svg');require('../../src/img/svg/fixed_button.svg');require('../../src/img/svg/hamburger.svg');require('../../src/img/svg/logo.svg');require('../../src/img/svg/mail.svg');require('../../src/img/svg/megafon.svg');require('../../src/img/svg/mi_logo.svg');require('../../src/img/svg/mobile-img-clients.svg');require('../../src/img/svg/payot.svg');require('../../src/img/svg/telegram_hover.svg');require('../../src/img/svg/telegram.svg');require('../../src/img/svg/tg.svg');require('../../src/img/svg/twinset.svg');require('../../src/img/svg/upsidelogo.svg');require('../../src/img/svg/whatsapp_hover.svg');require('../../src/img/svg/whatsapp.svg');require('../../src/img/svg/xiaomi.svg');require('../../src/img/svg/yandex.svg');